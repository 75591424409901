
/*******************************
          Responsive
*******************************/
/* Defaults */

.ui.progress:first-child {
  width: 150px;
  margin: 0px;
}
/*
.ui.menu .item {
  padding-top: 0px;
  padding-bottom: 0px;
}*/

/* Needed to fix an error with Semantic UI react
https://github.com/Semantic-Org/Semantic-UI-React/issues/2558
https://github.com/Semantic-Org/Semantic-UI-React/issues/2550
*/

.ui.modal {
margin-top: 0 !important;
position: unset;
}

.main.container {

  width: auto !important;
  max-width: 960px !important;
  margin-top: 5px;
}

.horizontal_ad { padding-top:50px; }

.rowone {
background: #e0e0e0;
background: -webkit-linear-gradient(#e0e0e0, #edeaea);
background: -o-linear-gradient(#e0e0e0, #edeaea);
background: -moz-linear-gradient(#e0e0e0, #edeaea);
background: linear-gradient(#e0e0e0, #edeaea);
}

.rowtwo {
background: #edeaea;
background: -webkit-linear-gradient(#edeaea, #f7f7f7);
background: -o-linear-gradient(#edeaea, #f7f7f7);
background: -moz-linear-gradient(#edeaea, #f7f7f7);
background: linear-gradient(#edeaea, #f7f7f7);
}

@media only screen and (min-width: 1024px) {

    .main.container {
      margin-left: 3em !important;
      margin-right: 387px !important;
    }

    /* Rail Default */
     .main.container > .right.rail
    {
      margin-left: 3em;
      padding-top: 2em;
      width: 319px;
      right: 20px;
      left: auto;
    }

}
@media only screen and (min-width: 768px) {
  /*  .desktop-only {
        display:block !important;
    }*/

    .mobile-only {
        display:none !important;
    }

}

@media only screen and (min-width:768px) and (max-width: 1023px) {
    .main.container {
      margin-right: 250px !important;
    }

    /* Rail Default */
    .main.container > .right.rail
    {
      margin-left: 3em;
      width: 250px;
    }

    #calls {
        margin-left: 25px;
        margin-right: 25px;
    }
}


@media only screen and (max-width: 767px) {
    .mobile-only {
        display:block !important;
    }
    #call-header {
        display:none !important;
    }
    .main.container
    {
      margin-left: 0px !important;
      margin-right: 0px !important;
    }
/*
    .ui.table:not(.unstackable) tr > td.desktop-only {
        display:none !important;
    } */
    .desktop-only {
        display:none !important;
    }
}
